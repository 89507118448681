import React, { useState } from 'react';

interface MenuProps {
  setSelectedMenuItem: (item: string) => void;
}

const Menu: React.FC<MenuProps> = ({ setSelectedMenuItem }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const menuItems: string[] = ['Vehicle', 'Color Code'];

  const handleMenuItemClick = (index: number, item: string) => {
    setActiveIndex(index);
    setSelectedMenuItem(item);
  };

  return (
    <ul className='cmp-vehicle-color-finder--menu'>
      {menuItems.map((item, index) => (
        <li
          key={index}
          className={activeIndex === index ? 'active' : ''}
          onClick={() => handleMenuItemClick(index, item)}
          onKeyDown={(e) => e.key === 'Enter' && handleMenuItemClick(index, item)}
          tabIndex={0}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

export default Menu;
