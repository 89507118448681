import React from 'react';
import ColorCard from '.';
import { IAcesData } from '../Algolia/IAlgolia';

interface IColorResults {
  dataset: IAcesData[];
  title: string | null;
}

const ColorResults: React.FC<IColorResults> = ({ dataset, title }) => (
  <>
    {dataset.length > 0 && (
      <div className='cmp-vehicle-color-finder--results'>
        <hr />
        <h2 className='cmp-vehicle-color-finder--title'>{title}</h2>
        <div className='color-card-container'>
          {dataset.map((color: IAcesData, index: number) => {
            return (
              <ColorCard
                key={index}
                colorCode={color.colorCode}
                name={color.colorName}
                hex={color.hex}
                imgUrl={color.ColorswatchImageLink}
              />
            );
          })}
        </div>
      </div>
    )}
  </>
);
export default ColorResults;
