/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import DropdownSelect from '../DropdownSelect';
import ColorResults from '../ColorCard/ColorResults';
import { IAcesData } from './IAlgolia';
import { useAlgoliaApi } from '../../hooks/useAlgoliaApi';
import ModalLink from '../ModalLink';
import { pushColorFinderAnalytics, VehicleSelection } from '../../../shared/utils/GA4DataLayerHelper';

const SearchByVehicle = () => {
  // Algolia facets stored in state to trigger re-renders
  const [years, setYears] = useState<string[]>([]);
  const [makes, setMakes] = useState<string[]>([]);
  const [models, setModels] = useState<string[]>([]);

  // Selected values stored in state to trigger re-renders
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');

  // Handles dropdown list enabled state
  const [makeDisabled, setMakeDisabled] = useState(true);
  const [modelDisabled, setModelDisabled] = useState(true);

  // Results
  const [colorDataResults, setColorDataResults] = useState<IAcesData[]>([]);
  const { getAlgoliaFacetData, getColorMatchByYearMakeModel } = useAlgoliaApi();

  // Dropdown listeners
  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    reset();
    setSelectedYear(event.target.value);
    setMakeDisabled(false);
  };

  const handleMakeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMake(event.target.value);
    setColorDataResults([]);
    setSelectedModel('');
    setModelDisabled(false);
  };

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModel(event.target.value);
  };

  const reset = () => {
    setSelectedMake('');
    setSelectedModel('');
    setMakeDisabled(true);
    setModelDisabled(true);
    setColorDataResults([]);
  };

  const showColors = useCallback(async () => {
    const colorMatches = (await getColorMatchByYearMakeModel(selectedYear, selectedMake, selectedModel)) as IAcesData[];
    setColorDataResults(colorMatches);
    const vehicleSelection: VehicleSelection = { selectedYear, selectedMake, selectedModel };
    pushColorFinderAnalytics(colorMatches, vehicleSelection);
  }, [getColorMatchByYearMakeModel, selectedYear, selectedMake, selectedModel]);

  // Get Years
  useEffect(() => {
    getAlgoliaFacetData('year').then((responseYears) => {
      const descYears = responseYears.reverse();
      setYears(descYears);
    });
  }, []);

  // Get Makes
  useEffect(() => {
    if (!selectedYear) return;

    getAlgoliaFacetData('make', undefined, {
      facetFilters: `year:${selectedYear}`,
    }).then((responseMakes) => {
      setMakes(responseMakes);
    });
  }, [selectedYear]);

  // Get Models
  useEffect(() => {
    if (!selectedMake) return;

    getAlgoliaFacetData('model', undefined, {
      facetFilters: `year:${selectedYear},make:${selectedMake}`,
    }).then((responseModels) => {
      setModels(responseModels);
    });
  }, [selectedMake, selectedYear]);

  // Show colors on model change
  useEffect(() => {
    if (!selectedModel) return;

    showColors();
  }, [selectedModel]);

  return (
    <>
      <div className='cmp-vehicle-color-finder--options search-by-vehicle'>
        <DropdownSelect
          label='Year'
          id='year'
          name='year'
          items={years}
          value={selectedYear}
          onChange={handleYearChange}
          disabled={false}
        />
        <DropdownSelect
          label='Make'
          id='make'
          name='make'
          items={makes}
          value={selectedMake}
          onChange={handleMakeChange}
          disabled={makeDisabled}
        />
        <DropdownSelect
          label='Model'
          id='model'
          name='model'
          items={models}
          value={selectedModel}
          onChange={handleModelChange}
          disabled={modelDisabled}
        />
      </div>

      <ModalLink />

      {colorDataResults.length > 0 && (
        <ColorResults
          dataset={colorDataResults}
          title={`${selectedYear} ${selectedMake} ${selectedModel} OEM Colors`}
        />
      )}
    </>
  );
};

export default SearchByVehicle;