/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import { IAcesData } from './IAlgolia';
import ColorResults from '../ColorCard/ColorResults';
import { useAlgoliaApi } from '../../hooks/useAlgoliaApi';
import ModalLink from '../ModalLink';
import { pushColorFinderAnalytics, ColorCodeSelection } from '../../../shared/utils/GA4DataLayerHelper';

const SearchByColorCode = () => {
  const [colorCode, setColorCode] = useState('');
  const [colorDataResults, setColorDataResults] = useState<IAcesData[]>([]);
  const [showNoResults, setShowNoResults] = useState(false);
  const { getColorMatchByColorCode } = useAlgoliaApi();

  const onColorCodeSubmit = useCallback(async () => {
    // If nothing was entered in the search, return
    if (colorCode.length <= 1) return;

    // Perform search
    const results = (await getColorMatchByColorCode(colorCode)) as IAcesData[];
    setColorDataResults(results);

    results.length === 0 ? setShowNoResults(true) : setShowNoResults(false);

    const colorCodeSelection: ColorCodeSelection = { colorCode };
    pushColorFinderAnalytics(results, colorCodeSelection)
  }, [colorCode]);

  return (
    <>
      <div className='cmp-vehicle-color-finder--options search-by-color-code'>
        <div className='color-code--input'>
          <label htmlFor='color-code'>Color Code</label>
          <input
            name='color-code'
            title='Enter Color Code for color matchs'
            value={colorCode}
            onChange={(c) => setColorCode(c.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && onColorCodeSubmit()}
          />
        </div>
        <button
          className='color-code--button'
          onClick={onColorCodeSubmit}
          onKeyDown={(e) => e.key === 'Enter' && onColorCodeSubmit}
        >
          Submit
        </button>
      </div>

      <ModalLink />

      {colorDataResults.length > 0 && (
        <ColorResults dataset={colorDataResults} title={`${colorDataResults[0].colorName}`} />
      )}

      {showNoResults && (
        <div className='cmp-vehicle-color-finder--no-results'>
          <hr />
          <h2 className='cmp-vehicle-color-finder--title'>Sorry, that color is not yet available.</h2>
          <h3 className='cmp-vehicle-color-finder--subtitle'>
            Please see Universal colors or contact your dealership.
          </h3>
        </div>
      )}
    </>
  );
};
export default SearchByColorCode;