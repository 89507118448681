import React, { useContext } from 'react';
import { SettingsContext } from '../VehicleColorFinder';

export interface ICardData {
  name: string;
  colorCode: string;
  hex: string;
  imgUrl: string;
}

const card = (cardData: ICardData) => {
  return (
    <div className='color-card'>
      {cardData.imgUrl ? (
        <img src={`${cardData.imgUrl}`} alt={`${cardData.name} color swatch`} className='color-card--color' />
      ) : (
        <div className='color-card--color' style={{ backgroundColor: `#${cardData.hex}` }} />
      )}

      <div className='color-card--name'>{cardData.name}</div>
      <div className='color-card--code'>{cardData.colorCode}</div>
    </div>
  );
};

const ColorCard = (cardData: ICardData) => {
  const { colorDetailPagePath } = useContext(SettingsContext);
  const cardUrl = `${colorDetailPagePath}.colorCode-${cardData.colorCode}.html`;

  return (
    <>
      {/* If path configured, wrap card in link */}
      {colorDetailPagePath && (
        <a href={cardUrl} className='color-card--link results-summary'>
          {card(cardData)}
        </a>
      )}

      {/* If no path is configured, just create card */}
      {!colorDetailPagePath && card(cardData)}
    </>
  );
};
export default ColorCard;
