import algoliasearch from 'algoliasearch';
import { SettingsContext } from '../components/VehicleColorFinder';
import { useContext } from 'react';
import { IAcesFacetHits } from '../components/Algolia/IAlgolia';

export const useAlgoliaApi = () => {
  const { appId, apiKey, indexName } = useContext(SettingsContext);
  const client = algoliasearch(appId, apiKey);
  const index = client.initIndex(indexName);

  // Algolia search
  const getAlgoliaFacetData = async (facetName: string, facetQuery?: string, options?: object) => {
    const { facetHits } = await index.searchForFacetValues(facetName, facetQuery as string, options);

    // Return value in data array
    return facetHits.map((f: IAcesFacetHits) => f.value);
  };

  // Get colors by Year, Make, Model
  const getColorMatchByYearMakeModel = async (year: string, make: string, model: string) => {
    const { hits } = await index.search('', { facetFilters: [`year:${year}`, `make:${make}`, `model:${model}`] });
    return hits;
  };

  // Get colors by Color Code
  const getColorMatchByColorCode = async (colorCode: string) => {
    const { hits } = await index.search('', { facetFilters: [`colorCode:${colorCode}`] });
    return hits;
  };

  return { getColorMatchByColorCode, getColorMatchByYearMakeModel, getAlgoliaFacetData };
};
