import React, { useContext, useEffect } from 'react';
import { SettingsContext } from '../VehicleColorFinder';

const ModalLink = () => {
  enum ModalStatus {
    show = 'modal__active',
  }

  const modalSelector = '.cbg-cmp-modal';
  const { modalId, modalLinkText } = useContext(SettingsContext);

  const openModal = () => {
    const id = modalId.replace('#', '');
    const modal = document.querySelector(`#${id} ${modalSelector}`) as HTMLElement;

    if (!modal) {
      console.warn('Modal no encontrado:', modalId);
      return;
    }

    modal.classList.add(ModalStatus.show);
    trapFocus(modal);
  };

  const trapFocus = (modal: HTMLElement) => {
    const focusableElements = Array.from(
      modal.querySelectorAll("button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"),
    ).filter(
      (el) => el instanceof HTMLElement && !el.hasAttribute('disabled') && el.offsetParent !== null,
    ) as HTMLElement[];

    if (focusableElements.length === 0) {
      console.warn('No se encontraron elementos enfocables dentro del modal.');
      return;
    }

    const firstFocusable = focusableElements[0];
    const lastFocusable = focusableElements[focusableElements.length - 1];

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key !== 'Tab') return;

      if (event.shiftKey) {
        // Shift + Tab: Move focus backward
        if (document.activeElement === firstFocusable) {
          event.preventDefault();
          lastFocusable?.focus();
        }
      } else {
        // Tab: Move focus forward
        if (document.activeElement === lastFocusable) {
          event.preventDefault();
          firstFocusable?.focus();
        }
      }
    };

    modal.addEventListener('keydown', handleKeydown);

    firstFocusable.focus();

    (modal as any)._removeKeydownListener = () => {
      modal.removeEventListener('keydown', handleKeydown);
    };
  };

  const releaseFocus = (modal: HTMLElement) => {
    const removeListener = (modal as any)._removeKeydownListener;
    if (removeListener) {
      removeListener();
    }
  };

  useEffect(() => {
    return () => {
      const id = modalId.replace('#', '');
      const modal = document.querySelector(`#${id} ${modalSelector}`) as HTMLElement;
      if (modal) {
        releaseFocus(modal);
      }
    };
  }, [modalId]);

  return (
    <button className='cmp-vehicle-color-finder--modal-link' onClick={openModal}>
      <div className='modal-link-content'>
        <span className='modal-link-text'>{modalLinkText}</span>
      </div>
    </button>
  );
};

export default ModalLink;
