import React from 'react';

interface DropdownProps {
  label: string;
  id: string;
  name: string;
  items: string[];
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled: boolean;
}

const DropdownSelect = ({ label, id, name, items, value, onChange, disabled }: DropdownProps) => {
  return (
    <div className='dropdown-select'>
      <label htmlFor={id} className=''>
        {label}
      </label>

      <select id={id} name={name} className='' value={value} onChange={onChange} disabled={disabled}>
        {items.length > 0 && (
          <>
            <option value='' disabled>
              Please select a {name}
            </option>
            {items.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </>
        )}
      </select>
    </div>
  );
};

export default DropdownSelect;
