import React, { ReactElement, useEffect, useState, useRef, createContext } from 'react';
import Menu from '../Menu';
import SearchByVehicle from '../Algolia/SearchByVehicle';
import SearchByColorCode from '../Algolia/SearchByColorCode';

interface IVehicleColorFinderModel {
  slingModelAPI: string;
}

export interface IComponentSettings {
  appId: string;
  apiKey: string;
  indexName: string;
  modalId: string;
  modalLinkText: string;
  colorDetailPagePath: string;
}

const settingsModel: IComponentSettings = {
  appId: '',
  apiKey: '',
  indexName: '',
  modalId: '',
  modalLinkText: '',
  colorDetailPagePath: '',
};

export const SettingsContext = createContext<IComponentSettings>(settingsModel);

const VehicleColorFinder = ({ slingModelAPI }: IVehicleColorFinderModel): ReactElement | null => {
  const [settings, setSettings] = useState<IComponentSettings>(settingsModel); // Component settings from JCR
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('Vehicle'); // Default to 'Vehicle'/ Wait for settings before loading component
  const [loading, setLoading] = useState(true);
  const prevSettings = useRef(settings);

  // Fetch JCR settings
  useEffect(() => {
    const fetchSettings = async () => {
      const response = await fetch(slingModelAPI);
      const json = await response.json();
      setSettings(json);
    };

    fetchSettings();
  }, [slingModelAPI]);

  // Wait until settings actually updates
  useEffect(() => {
    if (settings === prevSettings.current) return;
    setLoading(false);
  }, [settings]);

  return (
    <SettingsContext.Provider value={settings}>
      {!loading && (
        <>
          <Menu setSelectedMenuItem={setSelectedMenuItem} />
          {selectedMenuItem === 'Vehicle' ? <SearchByVehicle /> : <SearchByColorCode />}
        </>
      )}
    </SettingsContext.Provider>
  );
};

export default VehicleColorFinder;
