import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import VehicleColorFinder from './VehicleColorFinder';

const container: HTMLElement | null = document.querySelector('.cmp-vehicle-color-finder');

if (container) {
  const slingModelAPI = container.dataset.resourcePath ?? '';
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <VehicleColorFinder slingModelAPI={slingModelAPI} />
    </StrictMode>,
  );
}
