import { Question, Recommendation } from '../types/product-selector/types';
import { DataLayerRow } from '../types/types';
import { ResponseContainerProps } from '../../product-selector/components/response-container/response-container';

export const pushColorFinderAnalytics = (
  colorMatches: ColorMatch[],
  options: VehicleSelection | ColorCodeSelection,
) => {
  const isColorCodeQuiz: boolean = 'colorCode' in options;
  const isVehicleSearch: boolean = 'selectedYear' in options && 'selectedMake' in options && 'selectedModel' in options;

  if (!isColorCodeQuiz && !isVehicleSearch) {
    throw new Error('Expected the search to have a color code OR year/make/model');
  }

  const resultsArray: string | string[] = parseResults(colorMatches);

  if (isVehicleSearch && 'selectedYear' in options && 'selectedMake' in options && 'selectedModel' in options) {
    const escapedYear = escapeString(options.selectedYear);
    const escapedMake = escapeString(options.selectedMake);
    const escapedModel = escapeString(options.selectedModel);

    window.dataLayer.push({
      event: 'quiz_selection',
      quiz_year: `${escapedYear}`,
      quiz_make: `${escapedMake}`,
      quiz_model: `${escapedModel}`,
    });
  }

  window.dataLayer.push({
    event: 'quiz_results',
    quiz_type: isColorCodeQuiz ? 'Color Code' : 'Vehicle',
    quiz_result_title: getQuizResultTitle(options),
    quiz_result: resultsArray,
  });
};

const getQuizResultTitle = (options: VehicleSelection | ColorCodeSelection): string => {
  if ('colorCode' in options) {
    return `Color Code Search: ${options.colorCode}`;
  } else {
    const escapedYear = escapeString(options.selectedYear);
    const escapedMake = escapeString(options.selectedMake);
    const escapedModel = escapeString(options.selectedModel);
    return `${escapedYear} ${escapedMake} ${escapedModel} OEM Colors`;
  }
};

export interface VehicleSelection {
  selectedYear: string;
  selectedMake: string;
  selectedModel: string;
}

export interface ColorCodeSelection {
  colorCode: string;
}

interface ColorMatch {
  colorName: string;
  colorCode: string;
}

const escapeString = (str: string | undefined): string => {
  if (typeof str !== 'string') {
    return '';
  }
  return str.replace(/\\/g, '\\\\').replace(/'/g, "\\'").replace(/"/g, '\\"');
};

const parseResults = (colorMatches: ColorMatch[]): string | string[] => {
  if (colorMatches.length === 0) {
    return "No Results";
  }
  const resultsArray: string[] = [];
  colorMatches.forEach((colorMatch: { colorName: string; colorCode: string }) => {
    const escapedColorName = escapeString(colorMatch.colorName);
    const escapedColorCode = escapeString(colorMatch.colorCode);
    resultsArray.push(`${escapedColorName} ${escapedColorCode}`);
  });
  return resultsArray.length > 1 ? resultsArray : resultsArray[0];
};

export const pushProjectHelperAnalytics = (
  eventType: string,
  currentQuestion: number,
  questions: Question[],
  selectedResponses: ResponseContainerProps['selectedResponses'],
) => {
  const analyticsObject: DataLayerRow = {
    event: eventType,
    quiz_step: getCurrentStep(currentQuestion),
    quiz_step_name: getStepName(questions, currentQuestion),
  };

  const selectionsMade = getSelections(currentQuestion, questions, selectedResponses);
  if (selectionsMade !== undefined) {
    analyticsObject.selection = selectionsMade;
  }

  window.dataLayer.push(analyticsObject);
};

export const pushProjectHelperInteraction = (currentQuestion: number, questions: Question[], selection: string) => {
  window.dataLayer.push({
    event: 'quiz_interaction',
    quiz_step: getCurrentStep(currentQuestion),
    quiz_step_name: getStepName(questions, currentQuestion),
    selection: selection,
  });
};

export const pushProjectHelperResults = (recommendationsData: Recommendation[]) => {
  if (recommendationsData.length > 0) {
    const resultsArray: string[] = [];
    recommendationsData.forEach(result => {
      resultsArray.push(result.name);

      window.dataLayer.push({
        event: 'quiz_submit',
        result: resultsArray,
      });
    });
  };
};

const getSelections = (
  currentQuestion: number,
  questions: Question[],
  selectedResponses: ResponseContainerProps['selectedResponses'],
): string | string[] | undefined => {
  if (currentQuestion === Object.keys(questions).length) {
    return undefined;
  }
  const stepNum: number = currentQuestion;
  const thisQuestion: Question = questions[stepNum];
  const stepName: string = thisQuestion.name;
  const numberOfResponses: string = Object.keys(selectedResponses ?? {}).length.toString();
  // Account for hitting the previous button with no selections
  const responseForEachQuestion: boolean = numberOfResponses === getCurrentStep(stepNum);

  if (selectedResponses?.[stepName] === undefined) {
    return undefined;
  }

  if (responseForEachQuestion) {
    const thisResponse: Record<string, boolean> = selectedResponses[stepName];
    const selectionKeys: string[] = [];

    if (typeof thisResponse === 'object') {
      Object.keys(thisResponse).forEach((key) => {
        if (thisResponse[key]) {
          selectionKeys.push(key);
        }
      });
    }
    return selectionKeys.length > 1 ? selectionKeys : selectionKeys[0];
  }
};

const getStepName = (questions: Question[], currentQuestion: number) => {
  const thisQuestion: Question = questions[currentQuestion];
  return currentQuestion > questions.length - 1 ? 'Results Page' : thisQuestion.questionText.replace(/^\d+\.\s*/, '');
};

const getCurrentStep = (currentQuestion: number) => {
  return (currentQuestion + 1).toString();
};